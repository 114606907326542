import { T as Timepicker } from './Timepicker-c1e2bbf4.js';
export { T as BTimepicker } from './Timepicker-c1e2bbf4.js';
import { u as use, a as registerComponent } from './plugins-218aea86.js';
import './_rollupPluginBabelHelpers-df313029.js';
import './TimepickerMixin-d736b0a9.js';
import './FormElementMixin-b223d3c7.js';
import './config-e7d4b9c2.js';
import './helpers.js';
import './DropdownItem-55682322.js';
import './trapFocus-f0736873.js';
import './InjectedChildMixin-b4220787.js';
import './Input-20612b63.js';
import './Icon-60d47b31.js';
import './Field-3cb24eff.js';
import './Select-97781d4e.js';

var Plugin = {
  install: function install(Vue) {
    registerComponent(Vue, Timepicker);
  }
};
use(Plugin);

export { Plugin as default };
