import { _ as _defineProperty, a as _objectSpread2, b as _typeof } from './_rollupPluginBabelHelpers-df313029.js';

/**
 * +/- function to native math sign
 */
function signPoly(value) {
  if (value < 0) return -1;
  return value > 0 ? 1 : 0;
}
var sign = Math.sign || signPoly;

/**
 * Checks if the flag is set
 * @param val
 * @param flag
 * @returns {boolean}
 */
function hasFlag(val, flag) {
  return (val & flag) === flag;
}

/**
 * Native modulo bug with negative numbers
 * @param n
 * @param mod
 * @returns {number}
 */
function mod(n, mod) {
  return (n % mod + mod) % mod;
}

/**
 * Asserts a value is beetween min and max
 * @param val
 * @param min
 * @param max
 * @returns {number}
 */
function bound(val, min, max) {
  return Math.max(min, Math.min(max, val));
}

/**
 * Get value of an object property/path even if it's nested
 */
function getValueByPath(obj, path) {
  return path.split('.').reduce(function (o, i) {
    return o ? o[i] : null;
  }, obj);
}

/**
 * Extension of indexOf method by equality function if specified
 */
function indexOf(array, obj, fn) {
  if (!array) return -1;
  if (!fn || typeof fn !== 'function') return array.indexOf(obj);
  for (var i = 0; i < array.length; i++) {
    if (fn(array[i], obj)) {
      return i;
    }
  }
  return -1;
}

/**
 * Merge function to replace Object.assign with deep merging possibility
 */
var isObject = function isObject(item) {
  return _typeof(item) === 'object' && !Array.isArray(item);
};
var mergeFn = function mergeFn(target, source) {
  var deep = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  if (deep || !Object.assign) {
    var isDeep = function isDeep(prop) {
      return isObject(source[prop]) && target !== null && target.hasOwnProperty(prop) && isObject(target[prop]);
    };
    var replaced = Object.getOwnPropertyNames(source).map(function (prop) {
      return _defineProperty({}, prop, isDeep(prop) ? mergeFn(target[prop], source[prop], deep) : source[prop]);
    }).reduce(function (a, b) {
      return _objectSpread2(_objectSpread2({}, a), b);
    }, {});
    return _objectSpread2(_objectSpread2({}, target), replaced);
  } else {
    return Object.assign(target, source);
  }
};
var merge = mergeFn;

/**
 * Mobile detection
 * https://www.abeautifulsite.net/detecting-mobile-devices-with-javascript
 */
var isMobile = {
  Android: function Android() {
    return typeof window !== 'undefined' && window.navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function BlackBerry() {
    return typeof window !== 'undefined' && window.navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function iOS() {
    return typeof window !== 'undefined' && (window.navigator.userAgent.match(/iPhone|iPad|iPod/i) || window.navigator.platform === 'MacIntel' && window.navigator.maxTouchPoints > 1);
  },
  Opera: function Opera() {
    return typeof window !== 'undefined' && window.navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function Windows() {
    return typeof window !== 'undefined' && window.navigator.userAgent.match(/IEMobile/i);
  },
  any: function any() {
    return isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows();
  }
};
function removeElement(el) {
  if (typeof el.remove !== 'undefined') {
    el.remove();
  } else if (typeof el.parentNode !== 'undefined' && el.parentNode !== null) {
    el.parentNode.removeChild(el);
  }
}
function createAbsoluteElement(el) {
  var root = document.createElement('div');
  root.style.position = 'absolute';
  root.style.left = '0px';
  root.style.top = '0px';
  root.style.width = '100%';
  var wrapper = document.createElement('div');
  root.appendChild(wrapper);
  wrapper.appendChild(el);
  document.body.appendChild(root);
  return root;
}
function isVueComponent(c) {
  return c && c._isVue;
}

/**
 * Escape regex characters
 * http://stackoverflow.com/a/6969486
 */
function escapeRegExpChars(value) {
  if (!value) return value;

  // eslint-disable-next-line
  return value.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}
/**
 * Remove accents/diacritics in a string in JavaScript
 * https://stackoverflow.com/a/37511463
 */
function removeDiacriticsFromString(value) {
  if (!value) return value;
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
function multiColumnSort(inputArray, sortingPriority) {
  // NOTE: this function is intended to be used by BTable
  // clone it to prevent the any watchers from triggering every sorting iteration
  var array = JSON.parse(JSON.stringify(inputArray));
  var fieldSorter = function fieldSorter(fields) {
    return function (a, b) {
      return fields.map(function (o) {
        var field = o.field,
          order = o.order,
          customSort = o.customSort;
        if (typeof customSort === 'function') {
          return customSort(a, b, order !== 'desc');
        } else {
          var aValue = getValueByPath(a, field);
          var bValue = getValueByPath(b, field);
          var ord = aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
          return order === 'desc' ? -ord : ord;
        }
      }).reduce(function (p, n) {
        return p || n;
      }, 0);
    };
  };
  return array.sort(fieldSorter(sortingPriority));
}
function createNewEvent(eventName) {
  var event;
  if (typeof Event === 'function') {
    event = new Event(eventName);
  } else {
    event = document.createEvent('Event');
    event.initEvent(eventName, true, true);
  }
  return event;
}
function toCssWidth(width) {
  return width === undefined ? null : isNaN(width) ? width : width + 'px';
}

/**
 * Return month names according to a specified locale
 * @param  {String} locale A bcp47 localerouter. undefined will use the user browser locale
 * @param  {String} format long (ex. March), short (ex. Mar) or narrow (M)
 * @return {Array<String>} An array of month names
 */
function getMonthNames() {
  var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'long';
  var dates = [];
  for (var i = 0; i < 12; i++) {
    dates.push(new Date(2000, i, 15));
  }
  var dtf = new Intl.DateTimeFormat(locale, {
    month: format
  });
  return dates.map(function (d) {
    return dtf.format(d);
  });
}

/**
 * Return weekday names according to a specified locale
 * @param  {String} locale A bcp47 localerouter. undefined will use the user browser locale
 * @param  {String} format long (ex. Thursday), short (ex. Thu) or narrow (T)
 * @return {Array<String>} An array of weekday names
 */
function getWeekdayNames() {
  var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : undefined;
  var format = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'narrow';
  var dates = [];
  for (var i = 0; i < 7; i++) {
    var dt = new Date(2000, 0, i + 1);
    dates[dt.getDay()] = dt;
  }
  var dtf = new Intl.DateTimeFormat(locale, {
    weekday: format
  });
  return dates.map(function (d) {
    return dtf.format(d);
  });
}

/**
 * Accept a regex with group names and return an object
 * ex. matchWithGroups(/((?!=<year>)\d+)\/((?!=<month>)\d+)\/((?!=<day>)\d+)/, '2000/12/25')
 * will return { year: 2000, month: 12, day: 25 }
 * @param  {String} includes injections of (?!={groupname}) for each group
 * @param  {String} the string to run regex
 * @return {Object} an object with a property for each group having the group's match as the value
 */
function matchWithGroups(pattern, str) {
  var matches = str.match(pattern);
  return pattern
  // get the pattern as a string
  .toString()
  // suss out the groups
  .match(/<(.+?)>/g)
  // remove the braces
  .map(function (group) {
    var groupMatches = group.match(/<(.+)>/);
    if (!groupMatches || groupMatches.length <= 0) {
      return null;
    }
    return group.match(/<(.+)>/)[1];
  })
  // create an object with a property for each group having the group's match as the value
  .reduce(function (acc, curr, index, arr) {
    if (matches && matches.length > index) {
      acc[curr] = matches[index + 1];
    } else {
      acc[curr] = null;
    }
    return acc;
  }, {});
}

/**
 * Based on
 * https://github.com/fregante/supports-webp
 */
function isWebpSupported() {
  return new Promise(function (resolve) {
    var image = new Image();
    image.onerror = function () {
      return resolve(false);
    };
    image.onload = function () {
      return resolve(image.width === 1);
    };
    image.src = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoBAAEAAwA0JaQAA3AA/vuUAAA=';
  }).catch(function () {
    return false;
  });
}
function isCustomElement(vm) {
  return 'shadowRoot' in vm.$root.$options;
}
var isDefined = function isDefined(d) {
  return d !== undefined;
};

/**
 * Checks if a value is null or undefined.
 * Based on
 * https://github.com/lodash/lodash/blob/master/isNil.js
 */
var isNil = function isNil(value) {
  return value === null || value === undefined;
};

/**
 * Translates a touch event as a drag event.
 *
 * `event` must be a touch event.
 *
 * `options` must be an object with the following properties:
 * - `type`: new event type (required). must be one of the following:
 *     - `"dragstart"`
 *     - `"dragend"`
 *     - `"drop"`
 *     - `"dragover"`
 *     - `"dragleave"`
 * - `target`: new target element (optional). `clientX` and `clientY` will be
 *   translated if `target` is different from `event.target`.
 *
 * This function only works with single-touch events for now.
 */
var translateTouchAsDragEvent = function translateTouchAsDragEvent(event, options) {
  var type = options.type,
    target = options.target;
  var translateX = 0;
  var translateY = 0;
  if (target != null && target !== event.target) {
    var baseRect = event.target.getBoundingClientRect();
    var targetRect = target.getBoundingClientRect();
    translateX = targetRect.left - baseRect.left;
    translateY = targetRect.top - baseRect.top;
  }
  var touch = event.touches[0] || event.changedTouches[0];
  return new DragEvent(type, {
    dataTransfer: new DataTransfer(),
    bubbles: true,
    screenX: touch.screenX,
    screenY: touch.screenY,
    clientX: touch.clientX + translateX,
    clientY: touch.clientY + translateY,
    ctrlKey: event.ctrlKey,
    shiftKey: event.shiftKey,
    altKey: event.altKey,
    metaKey: event.metaKey
  });
};

export { bound, createAbsoluteElement, createNewEvent, escapeRegExpChars, getMonthNames, getValueByPath, getWeekdayNames, hasFlag, indexOf, isCustomElement, isDefined, isMobile, isNil, isVueComponent, isWebpSupported, matchWithGroups, merge, mod, multiColumnSort, removeDiacriticsFromString, removeElement, sign, toCssWidth, translateTouchAsDragEvent };
